'use client';

import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { useLocalization } from '@akinon/next/hooks';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { resetHeaderState } from '@akinon/next/redux/reducers/header';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { getCookie } from '@akinon/next/utils';
import { GoogleTagManager } from '@next/third-parties/google';
import OrganizationJSON from '@theme/models/jsons/organization.json';
import { ROUTES } from '@theme/routes';
import { pushPageView } from '@theme/utils/gtm';
import { createHideOnApp } from '@theme/utils/helper';
import { swanPageView } from '@theme/utils/swan';
import { useSession } from 'next-auth/react';
import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState } from 'react';

export default function RootTemplate({
  children
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const URL = process.env.NEXT_PUBLIC_URL;
  const currentUrl = URL + pathname + searchParams;
  const { locale, currency } = useLocalization();

  const {
    data: deliveryModeData,
    isLoading: deliveryModeIsLoading,
    isSuccess: deliveryModeIsSuccess
  } = useGetWidgetQuery('delivery-mode');

  const [theme, setTheme] = useState('default');
  const { data: session, status } = useSession();

  useEffect(() => {
    dispatch(closeMiniBasket());
    dispatch(resetHeaderState());
  }, [dispatch, pathname, searchParams]);

  useEffect(() => {
    if (pathname === ROUTES.HOME) {
      window.scrollTo(0, 0);
    }
    pushPageView(pathname);
    swanPageView(pathname);
  }, [pathname]);

  const jsonLdWebSite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: currentUrl,
    name: 'Lulu Qatar',
    alternateName: 'Lulu Hypermarket Qatar '
  };

  const jsonLdOrganization = {
    ...OrganizationJSON,
    url: currentUrl,
    name: 'Project Zero',
    logo: `${URL}/akn-logo.svg`
  };

  useEffect(() => {
    const parsedPathname = pathname.split('/');
    if (
      parsedPathname.includes('checkout') ||
      parsedPathname.includes('basket')
    ) {
      const ymDivBar = document.getElementById('ymDivBar');
      ymDivBar?.classList.add('invisible');
    } else {
      const ymDivBar = document.getElementById('ymDivBar');
      ymDivBar?.classList.remove('invisible');
    }
  }, [pathname]);

  useEffect(() => {
    const date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    if (deliveryModeIsSuccess) {
      const storedTheme = getCookie('theme');

      if (storedTheme) {
        setTheme(storedTheme);
        document.cookie =
          'theme' +
          '=' +
          storedTheme +
          '; expires=' +
          date.toUTCString() +
          '; path=/; SameSite=None; Secure';
      } else {
        setTheme('scheduled');
        document.cookie =
          'theme' +
          '=' +
          'scheduled' +
          '; expires=' +
          date.toUTCString() +
          '; path=/; SameSite=None; Secure';
      }
    }
  }, [deliveryModeIsSuccess]);

  useEffect(() => {
    const root = document.body;
    root.classList.remove('default');
    if (theme == 'scheduled') {
      root.classList.add('scheduled');
      root.classList.remove('express');
    } else if (theme == 'express') {
      root.classList.add('express');
      root.classList.remove('scheduled');
    }
  }, [theme]);

  useEffect(() => {
    if (status !== 'loading') {
      window.ymConfig = {
        bot: 'x1649167667400',
        host: 'https://r1.cloud.yellow.ai',
        payload: {
          name: session?.user?.firstName,
          email: session?.user?.email,
          phoneNumber: session?.user?.phone
        }
      };

      const ymScript = document.createElement('script');
      ymScript.type = 'text/javascript';
      ymScript.async = true;
      ymScript.src =
        'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
      document.body.appendChild(ymScript);

      ymScript.onload = () => {
        const parsedPathname = window.location.pathname.split('/');
        const ymDivBar = document.getElementById('ymDivBar');

        if (
          parsedPathname.includes('checkout') ||
          parsedPathname.includes('basket')
        ) {
          ymDivBar?.classList.add('invisible');
        } else {
          ymDivBar?.classList.remove('invisible');
        }
      };
    }
  }, [session, status]);

  return (
    <>
      {/* Google Tag Manager */}
      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GTM_KEY || 'GTM-T59PQ3JC'}
      />

      {/* End Google Tag Manager */}
      <meta
        name="google-site-verification"
        content="xap-z4HMc2KO5o5rOKEOankceetHEEsxIhMarxRXqmQ"
      />
      {/* Emarsys Web Extend Script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            var ScarabQueue = ScarabQueue || [];
            (function(id) {
              if (document.getElementById(id)) return;
              var js = document.createElement('script');
              js.id = id;
              js.src = '//cdn.scarabresearch.com/js/189390859720DA81/scarab-v2.js';
              var fs = document.getElementsByTagName('script')[0];
              fs.parentNode.insertBefore(js, fs);
            })('scarab-js-api');
          `
        }}
      />

      {/* Swan Ecom Events Tracking */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function (url) {
              _swan = window._swan || {};
              const d = document;
              const g = d.createElement('script');
              const s = d.getElementsByTagName('script')[0];
              g.type = 'text/javascript';
              g.async = true;
              g.src = 'https://swan-web-sdk.azureedge.net/trackingjs?country=${locale.split('-')[1].toUpperCase()}&currency=${currency.toUpperCase()}';
              s.parentNode.insertBefore(g, s);
              g.onload = function () {
                _swan.init({
                app_id: 'lulu_68363'
                });
              };
            })();
          `
        }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdWebSite) }}
      />
      <Script
        strategy="beforeInteractive"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLdOrganization)
        }}
      />
      {createHideOnApp()}
      {children}
    </>
  );
}
